import React from 'react';
import { Layout } from 'antd';
import Header from '../components/PageLayout/Header';
import Footer from '../components/PageLayout/Footer';

import SidebarWrapper from '../components/PageLayout/Sidebar';
import Home from '../components/PageFragments/HomePage/Home';

export default () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SidebarWrapper>
        <>
          <Home />
        </>
      </SidebarWrapper>
      <Footer />
    </Layout>
  </Layout>
);
