import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import AboutBox from '../../AboutBox';

import SEO from '../../Seo';

const pageText = {
  paraOne: 'Hallo mein Name ist Gerald. Ich biete IT- und Unternehmensberatung im Bereich der agilen und klassischen Vorgehensweisen in der IT.',
};

const AboutMe = () => (
  <>
    <div>
      <SEO
        title="Dienstleistungen"
        description={pageText.paraOne}
        path=""
        keywords={['Gerald', 'Drausinger', 'ThinkBright', 'Agile Coaching', 'Product Owner', 'Scrum Master', 'Business Analyse', 'Projektmanagement']}
      />
      <h1 className="titleSeparate">Dienstleistungen</h1>
      <p>
        {pageText.paraOne}
      </p>
    </div>
    <h2>Agile Vorgehensweisen</h2>
    <p>
      Agiles Coaching kann Ihnen helfen, von klassischen Vorgehensweisen auf agile Methoden
      umzusteigen.
      Sollten Sie bereits agil arbeiten, kann Ihnen agiles Coaching helfen kontinuierliche
      Verbesserung in Ihren Teams zu fördern.
    </p>
    <p>
      Wir können Sie in unserer Tätigkeit direkt als Product Owner oder Scrum Master
      unterstützen, hierbei werden wir in den genannten Rollen tätig und können
      Sie aktiv in agilen Vorgehensweisen unterstützen.
    </p>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12} lg={8}>
        {/* <Link to="/agiles-coaching"> */}
        <AboutBox
          svg="agilecoaching.svg"
          alt="Agiles Coaching"
          textH3="Agiles Coaching"
        />
        {/* </Link> */}
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        {/* <Link to="/product-owner"> */}
        <AboutBox
          svg="productowner.svg"
          alt="Product Owner"
          textH3="Product Owner"
        />
        {/* </Link> */}
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        {/* <Link to="/scrum-master"> */}
        <AboutBox
          svg="scrummaster.svg"
          alt="Scrum Master"
          textH3="Scrum Master"
        />
        {/* </Link> */}
      </Col>
    </Row>
    <p>
      Wichtig bei allen agilen Vorgehensweisen ist die Beachtung des&nbsp;
      <a href="https://agilemanifesto.org/">agilen Manifests</a>
      , dies ist
      deutlich wichtiger als die sture Einhaltung agiler Methoden. Leider haben
      bereits viele Firmen schlechte Erfahrungen mit agilen Transformationen gemacht,
      oft ist dies auf eine unvollständige Umsetzung oder Berücksichtigung der
      agilen Grundprinzipien zurückzuführen (beispielsweise Vernachlässigung des Faktors Mensch).
    </p>
    <p>
      Einführung von Agilität ist in jedem Unternehmen anders und erfordert
      einen feinfühligen agilen Coach, um die für Sie und Ihr Team beste Lösung zu finden.&nbsp;
      <Link to="/kontakt">Kontaktieren Sie uns</Link>
      &nbsp;wenn Sie Wissen möchten was uns im Vorgehen von anderen Unternehmen unterscheidet
      und wie wir Sie aktiv auf Ihrem Weg zu mehr Agilität unterstützen können.
    </p>
    <h2>Klassische Vorgehensweisen</h2>
    <p>
      Auch bei den klassischen Vorgehensweisen können wir Sie aktiv in den Rollen als
      Projektmanager und Business Analyst begleiten. Durch unsere Expertise in
      zahlreichen agilen und klassisch durchgeführten Projekten, können wir Ihnen
      eine Projektbegleitung mit dem besten aus beiden Welten bieten und dabei
      individuell auf Ihr Projekt und Ihre Herausforderungen angepasst Vorgehen.
    </p>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12} lg={8}>
        {/* <Link to="/projektmanagement"> */}
        <AboutBox
          svg="projectmanager.svg"
          alt="Projektmanagement"
          textH3="Projektmanagement"
        />
        {/* </Link> */}
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        {/* <Link to="/business-analyse"> */}
        <AboutBox
          svg="businessanalyse.svg"
          alt="Business Analyse"
          textH3="Business Analyse"
        />
        {/* </Link> */}
      </Col>
    </Row>
  </>
);
export default AboutMe;
